@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #2caeba;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #576375;
  --green-color: #2caeba;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0;
}
